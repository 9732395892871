<template>
  <svg
    id="pdf"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      id="Path_5950"
      data-name="Path 5950"
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <g
      id="Group_4492"
      data-name="Group 4492"
      transform="translate(4.707 2.275)"
    >
      <g
        id="Group_4491"
        data-name="Group 4491"
      >
        <path
          id="Path_5303"
          data-name="Path 5303"
          d="M7141.912,2145.331h-8.122s-1.379.149-1.379,1.379v16.042s.079.969,1.379.969h10.887s1.514.306,1.514-1.753v-12.445Z"
          transform="translate(-7132.001 -2144.945)"
          :fill="backgroundColor"
        />
        <path
          id="Path_5301"
          data-name="Path 5301"
          d="M78.468,4.171,74.416.119A.4.4,0,0,0,74.13,0H65.621A1.622,1.622,0,0,0,64,1.621V17.828a1.622,1.622,0,0,0,1.621,1.621H76.966a1.622,1.622,0,0,0,1.621-1.621V4.457A.4.4,0,0,0,78.468,4.171ZM74.535,1.383,77.2,4.052H75.345a.811.811,0,0,1-.81-.81Zm3.242,16.445a.811.811,0,0,1-.81.81H65.621a.811.811,0,0,1-.81-.81V1.621a.811.811,0,0,1,.81-.81h8.1V3.242a1.622,1.622,0,0,0,1.621,1.621h2.431Z"
          transform="translate(-64)"
          :fill="lineColor"
        />
        <path
          id="Path_5302"
          data-name="Path 5302"
          d="M134.928,176.078a11.734,11.734,0,0,1-.969-.836c-.309-.309-.584-.608-.823-.893a9.072,9.072,0,0,0,.536-2.062c0-1.345-.486-1.621-1.216-1.621-.554,0-1.216.288-1.216,1.66a3.885,3.885,0,0,0,.988,2.192c-.161.49-.349,1.056-.561,1.693-.1.306-.213.589-.33.851-.1.042-.188.085-.277.13-.322.161-.628.306-.912.44-1.294.613-2.149,1.018-2.149,1.818,0,.581.631.941,1.216.941.753,0,1.891-1.006,2.722-2.7a16.123,16.123,0,0,1,2.781-.75,4.648,4.648,0,0,0,1.79,1.02c1,0,1.216-.581,1.216-1.068,0-.958-1.094-.958-1.621-.958A9.6,9.6,0,0,0,134.928,176.078Zm-5.713,3.5a.64.64,0,0,1-.405-.13c0-.287.857-.693,1.685-1.086l.16-.076C130.047,179.171,129.445,179.58,129.216,179.58Zm2.836-7.255c0-.849.264-.849.405-.849.286,0,.405,0,.405.81a6.5,6.5,0,0,1-.322,1.265A2.49,2.49,0,0,1,132.052,172.326Zm.311,4.355q.038-.106.074-.214c.15-.451.286-.856.406-1.221q.252.278.544.569c.076.076.264.247.515.461C133.4,176.385,132.871,176.52,132.363,176.681Zm4.552.211c0,.182,0,.258-.376.26a2.986,2.986,0,0,1-.681-.389c.114-.013.2-.019.246-.019C136.7,176.744,136.872,176.8,136.914,176.892Z"
          transform="translate(-125.569 -164.183)"
          :fill="lineColor"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: '#00CCCC',
    },
    color: {
      type: String,
      default: '#ffffff',
    },
  },
  data() {
    return {
      baseColor: '#00CCCC',
    };
  },
  computed: {
    backgroundColor() {
      return this.bgColor ? this.bgColor : this.baseColor;
    },
    lineColor() {
      return this.color ? this.color : '#ffffff';
    },
  },
};
</script>