<template>
  <section class="admin-general-terms-of-payment-and-delivery">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>
          Allgemeine Liefer- und Rechnungsbedingungen für die auf dem TyresNParts Marktplatz angebotenen Produkte
          Liefergebiet Deutschland
        </h1>
        <h2>(Version für Käufer, Sept 2021)</h2>
        <p class="description">
          Stand: OKTOBER 2021
        </p>
      </div>

      <v-card
        rounded="sm"
        class="bank"
      >
        <div class="upper">
          <p>Die vorliegenden allgemeinen Liefer- und Rechnungsbedingungen gelten für alle zum oder nach dem 01.09.2021 geschlossenen Kaufverträge über Produktlieferungen innerhalb des Gebiets. </p>
          <ol>
            <h2>A) Liefer- und Rechnungsbedingungen für von Großhändlern angebotene Reifen, Felgen, Kompletträder, Ventile und RDKS-Sensoren: </h2>
            <ol>
              <li>Lieferungen innerhalb des Gebiets ab zwei Reifen, Felgen, Kompletträdern, Ventilen oder RDKS-Sensoren erfolgen ungeachtet des Sitzes des Lieferanten ohne zusätzliche Lieferkosten zum vom Käufer bestimmten Lieferort. Sofern sich der Lieferort innerhalb des Gebiets befindet, ist der Käufer berechtigt, diesen frei zu wählen. </li>
              <li>
                Lieferungen innerhalb des Gebiets eines/einer einzelnen Reifens, Felge, Komplettrads, Ventils oder RDKS-Sensors können zusätzliche Lieferkosten nach Ermessen des Lieferanten zur Folge haben. Im vorgenannten Fall sind die entsprechenden Lieferkosten als von der Produktposition separate Position in der Rechnung aufzuführen. Die Höchstlieferkosten sind nach Produktkategorie in unten stehender Tabelle aufgeführt; sie dienen der Verbesserung der Preistransparenz für Käufer und zur Verhinderung von versteckten Kosten durch überhöhte Versandgebühren:
                <v-simple-table class="mt-4">
                  <thead>
                    <tr>
                      <th>Produkt-kategorien</th>
                      <th>Alle Reifen außer für schwere KFZ, Landwirtschafts-, Bau und Industriefahrzeuge (ausgenommen sind Reifen für moterisierte Zweiräder und ATV*) </th>
                      <th>Felge</th>
                      <th>Komplettrad</th>
                      <th>Reifen für schwere Kfz, Landwirtschafts-, Bau- und Industriefahrzeuge</th>
                      <th>RDKS-Sensor und Ventile</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Höchst-lieferkosten pro gelieferter Einheit </th>
                      <td class="text-nowrap">
                        6,90€<br>
                        exklusive Steuern
                      </td>
                      <td class="text-no-wrap">
                        6,90€<br>
                        exklusive Steuern
                      </td>
                      <td class="text-no-wrap">
                        6,90€<br>
                        exklusive Steuern
                      </td>
                      <td class="text-no-wrap">
                        20€<br>
                        exklusive Steuern
                      </td>
                      <td class="text-no-wrap">
                        6,90€<br>
                        exklusive Steuern
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </li>
              <li>Dem Lieferanten ist ausschließlich der Verkauf von neuen oder runderneuerten Reifen mit Herstellungsdatum innerhalb der letzten drei (3) Jahre gestattet. Ungeachtet des Vorstehenden ist dem Lieferanten der Verkauf von ausschließlich zur Verwendung auf „touristischen/Kleinbus-Anhängern“ vorgesehenen Neureifen nur gestattet, sofern deren Herstellungsdatum nicht länger als 18 Monate zurückliegt. </li>
              <li>
                Von einem Käufer vor 12:00 Uhr (Ortszeit des Gebiets) bestellte Produkte sind vom Lieferanten am selben Tag zu versenden und haben beim Käufer innerhalb der folgenden Lieferfristen einzugehen:
                <ul>
                  <li>•	in der Regel zwei Tage ab Auftragsbestätigung (Datum Kaufvertrag) bei Produkten von Lieferanten mit einem Lager innerhalb des Gebiets,</li>
                  <li>•	in der Regel drei Tage ab Auftragsbestätigung (Datum Kaufvertrag) bei Produkten von Lieferanten mit keinem Lager innerhalb des Gebiets.</li>
                </ul>
              </li>
              <li>Produktlieferungen auf Inseln innerhalb des Gebiets unterliegen einem Preisaufschlag. Den entsprechenden Aufschlag hat der Lieferant dem Käufer spätestens bei Auftragserteilung mitzuteilen. Der Preisaufschlag ist als von der Produktposition separate Position in der Rechnung aufzuführen.</li>
              <li>Für die Untersuchungs- und Rügepflicht des Käufers gilt § 377 HGB. Der Käufer hat die gelieferten Produkte bei Ablieferung auf etwaige Mängel (insbesondere auf Abweichungen von der bestellten Menge oder dem bestellten Typ bzw. Verschmutzungen) zu untersuchen. Offene Mängel sind zur Wahrung der Rechte des Käufers unverzüglich gegenüber dem Fahrer anzuzeigen (z.B. schriftlicher Vermerk auf dem Transportdokument) und dem Lieferanten innerhalb von drei Werktagen ab Ablieferung mitzuteilen. Der TNP Marktplatz sieht vor, dass eine Produktlieferung im Rahmen eines Kaufvertrags als vom Käufer angenommen gilt, sofern er die Lieferung nicht innerhalb von 10 Tagen ab Datum der entsprechenden Rechnung reklamiert.</li>
              <li>Die Rückgabe von mangelfrei gelieferten Produkten ist grundsätzlich ausgeschlossen. In Ausnahmefällen ist es dem Käufer jedoch gestattet, die Rückgabe der von ihm bestellten und ihm mangelfrei gelieferten Produkte innerhalb von 10 Werktagen ab Lieferung dieser Produkte zu verlangen. Die Berechnung einer Handlingspauschale liegt im Ermessen des Lieferanten. Sie beträgt grundsätzlich 10 % des in Rechnung gestellten Kaufpreises. Der Käufer trägt die vom Lieferanten angegebenen Rücksendekosten. Die gesetzlichen Regelungen zur Sachmängelgewährleistung bleiben unberührt. </li>
              <li>Der Lieferant verpflichtet sich, die vom Käufer bestellten Produkte und keine Ersatzprodukte zu liefern. Ist ein Lieferant nicht in der Lage, die bestellten Produkte gemäß den Bestimmungen des Kaufvertrags zu liefern, so hat er den Käufer unverzüglich davon zu unterrichten. </li>
              <li>Bei Bestellungen über Felgen hat der Lieferant sicherzustellen, dass diese für das vom Käufer zum Zeitpunkt der Bestellung gewählte Fahrzeug geeignet sind. </li>
              <li>Rechnungen im Rahmen von Kaufverträgen, die zwischen Käufer und einem Lieferanten geschlossen wurden, vom Lieferanten ausschließlich an den betreffenden Käufer zu richten.</li>
              <li>Die Lieferscheinnummer und die vom TNP Marktplatz generierte Bestellnummer sind auf der entsprechenden Rechnung, den zu den entsprechenden Packstücken gehörenden Lieferunterlagen sowie auf den Packstückkennzeichnungen zu vermerken. Die „Packstück“-Unterlagen sowie die dem Kaufvertrag entsprechende Rechnung hat der Lieferant dem Käufer elektronisch über das entsprechende Marketplace-Tool im „.PDF“- und „.CSV“-Format zu übermitteln. </li>
              <li>Werden im Rahmen eines Kaufvertrags bestellte Produkte nicht innerhalb von 5 Werktagen ab Datum des Abschlusses des Kaufvertrags geliefert, sieht der TNP Marktplatz vor, dass der Käufer grundsätzlich die Möglichkeit der kostenfreien Stornierung des entsprechenden Kaufvertrags hat. Dies umfasst eine kostenfreie Rücksendung von Produkten, die Käufer nach einer derartigen Stornierung erhält. </li>
              <li>Der TNP Marktplatz sieht vor, dass die Zahlung des Kaufpreises grundsätzlich mittels zugunsten des Lieferanten erteilten SEPA Lastschriftmandats zwischen Lieferant und Käufer erfolgt. Der Kaufpreis ist innerhalb der in der Rechnung angegebenen Zahlungsfrist, grundsätzlich 14 Tage, einzuziehen. Individuelle Vereinbarungen sind Käufer und Lieferant vorbehalten.</li>
            </ol>
            <h2>B) Liefer- und Rechnungsbedingungen für von Großhändlern angebotene Fahrzeugersatzteile: </h2>
            <p>Auf dem TNP Marktplatz gelistete Teile-Großhändler verpflichten sich, bestellte Ersatzteile innerhalb der oben genannten Lieferfristen für Reifen, Felgen, Kompletträder, RDKS-Sensoren und Ventile zu liefern. Darüberhinaus gilt in Abweichung von lit. A was folgt:</p>
            <ol>
              <li>Im Rahmen eines mit einem Teile-Großhändler geschlossenen Kaufvertrags bestellte Ersatzteile sind ab einem Kaufvertragspreis von 60 Euro exklusive Steuern oder mehr kostenfrei zu liefern. Unterschreitet der Kaufvertragspreis 60 Euro exklusive Steuern, so ist die Lieferung mit höchstens 6,90 Euro exklusive Steuern in Rechnung zu stellen.</li>
              <li>Zerlegte, bereits verbaute oder sich nicht mehr in der Originalverpackung oder in verkäuflichem Zustand befindliche Produkte sind von der Rückgabe ausgeschlossen.</li>
              <li>Pfandprodukte wie Batterien können nach der Bestellung einer neuen Batterie nicht mehr an Teile-Großhändler zurückgegeben werden. In diesem Fall ist der Käufer für das Recycling des entsprechenden Produkts zuständig.</li>
            </ol>
          </ol>
        </div>
      </v-card>
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-general-terms-of-payment-and-delivery.scss";
</style>

<script>

export default {
  components: {
  },
};
</script>
