<template>
  <section class="admin-bank">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>{{ $t('langkey.bank-sepa') }}</h1>
        <p
          class="description"
          v-html="$t('langkey.admin-bank-description')"
        />
      </div>

      <v-card
        rounded="sm"
        class="bank"
      >
        <div class="upper">
          <div class="title-header">
            <h6>{{ $t('langkey.bank-sepa') }}</h6>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.account-holder') }} :
            </p>
            <p class="value">
              {{ directDebitData.accountHolder }}
            </p>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.iban') }} :
            </p>
            <p class="value">
              {{ directDebitData.iban }}
            </p>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.bic') }} :
            </p>
            <p class="value">
              {{ directDebitData.bic }}
            </p>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.bank-name') }} :
            </p>
            <p class="value">
              {{ directDebitData.bankName }}
            </p>
          </div>
        </div>

        <div class="lower">
          <div class="title-header">
            <h6>{{ $t('langkey.sepa-direct-debit-mandate') }}</h6>
          </div>
          <label class="checkbox">
            <base-checkbox
              v-model="customerInformation.sepaGlobalApproval"
              reverse
              disabled
            >
              <template #custom-text>
                <span class="ml-2">
                  {{ $t('langkey.grant-sepa-authorization') }}
                </span>
              </template>
            </base-checkbox>
          </label>
          <p
            class="information"
            v-html="$t('langkey.request-revoke-sepa-or-change-bank-info')"
          />
          <v-btn
            depressed
            plain
            class="download-pdf__button icon"
            @click="onDownloadPdf"
          >
            <icon-pdf /> {{ $t('langkey.download-pdf') }}
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-bank.scss";
</style>

<script>
import IconPdf from '@/components/icons/IconPdf';
import { mapState } from 'vuex';

export default {
  components: {
    IconPdf,
  },
  computed: {
    ...mapState({
      directDebitData: (state) => state.directDebit.directDebitData,
      customerInformation: (state) => state.registration.customerInformation,
    }),
  },
  created() {
    this.$store.dispatch('directDebit/loadDirectDebit');
    this.$store.dispatch('registration/getCustomerInformation');
  },
  methods: {
    onDownloadPdf() {
      this.$store.dispatch('directDebit/getDirectDebitPdf')
        .then((response) => {
          const buffer = response.data;
          const blob = new Blob([buffer], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'DirectDebit';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.error-download-pdf'),
            message: this.$t('langkey.error-download-pdf-message'),
          });
        });
    },
  },
};
</script>